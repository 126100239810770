const parseKeywords = (text: string) => {

   try {
      const keywords = JSON.parse(text);
      if (keywords.adjectives && keywords.nouns) {
         return [...keywords.adjectives, ...keywords.nouns];
      }
      return false;
   } catch (error) {
      console.error("Error parseKeywords JSON:", error);
   }
   return;
};


const generateContent = async (text: string | undefined) => {
   try {
      // const getNlpSite = "http://127.0.0.1:8787";
      const getNlpSite = process.env.REACT_APP_CF_DOTA_NLP;
      // const getNlpSite = process.env.REACT_APP_CF_DOTA_NLP;
      if (getNlpSite) {
         const getKeywords = await fetch(getNlpSite, {
            method: "POST",
            headers: {
               "Content-Type": "application/json",
            },
            body: JSON.stringify(text),
         });
         const keywordsResults = await getKeywords.text();
         return parseKeywords(keywordsResults);
      }
   } catch (error) {

      throw new Error(`Error generateContent: ${error}`);
   }
};
export { generateContent };
