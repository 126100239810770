
import { Track } from "@spotify/web-api-ts-sdk";

export class TrackMatcher {
   private foundTracks: Set<string> = new Set();
   private normalizedTracks: Map<string, string> = new Map();

   private normalizeString(str: string): string {
      return str
         .toLowerCase()
         .replace(/[^\w\s]|_/g, '') // Remove special characters and extra spaces
         .replace(/\s+/g, ' ')
         .replace(/\b(feat|ft|featuring|with|prod|produced by)\b/g, '')
         .replace(/[-–]?\s*(remaster(ed)?|remix|edit|version|live|session|extended|radio|single|original|mix|mono|stereo|deluxe|anniversary|edition)\b.*/gi, '')
         .replace(/[\(\[\{](\d{4})[\)\]\}]/g, '')
         .replace(/\((.*?)\)|\[(.*?)\]|\{(.*?)\}/g, '') // Remove common parenthetical additions
         .replace(/\s+/g, ' ')
         .trim();
   }

   private getTrackKey(track: Track): string {
      const artistName = this.normalizeString(track.artists[0].name);
      const trackName = this.normalizeString(track.name);
      return `${artistName} -${trackName} `;
   }

   private areSimilarTracks(key1: string, key2: string): boolean {
      if (key1 === key2) return true;

      const [artist1, title1] = key1.split('-');
      const [artist2, title2] = key2.split('-');

      if (artist1 !== artist2) {
         const artistWords1 = new Set(artist1.split(' '));
         const artistWords2 = new Set(artist2.split(' '));
         const commonWords = [...artistWords1].filter(word => artistWords2.has(word));
         if (commonWords.length * 2 < Math.max(artistWords1.size, artistWords2.size)) return false;
      }
      return title1 === title2;
   }

   public filterDuplicates(tracks: Track[]): Track[] {
      const uniqueTracks: Track[] = [];
      for (const track of tracks) {
         const trackKey = this.getTrackKey(track);

         if (this.foundTracks.has(trackKey)) continue;

         let isDuplicate = false;
         for (const existingKey of this.foundTracks) {
            if (this.areSimilarTracks(trackKey, existingKey)) {
               isDuplicate = true;
               break;
            }
         }

         if (!isDuplicate) {
            this.foundTracks.add(trackKey);
            this.normalizedTracks.set(trackKey, `${track.artists[0].name} - ${track.name} `);
            uniqueTracks.push(track);
         }
      }
      return uniqueTracks;
   }

   public getSimilarTrackGroups(): Map<string, string[]> {
      const groups = new Map<string, string[]>();
      const processedKeys = new Set<string>();

      for (const [key1, original1] of this.normalizedTracks) {
         if (processedKeys.has(key1)) continue;

         const similarGroup = [original1];
         for (const [key2, original2] of this.normalizedTracks) {
            if (key1 !== key2 && !processedKeys.has(key2) && this.areSimilarTracks(key1, key2)) {
               similarGroup.push(original2);
               processedKeys.add(key2);
            }
         }

         if (similarGroup.length > 1) {
            groups.set(original1, similarGroup.slice(1));
         }
         processedKeys.add(key1);
      }

      return groups;
   }
}

// Example usage:
// const matcher = new TrackMatcher();
// const testTracks = [
//     {
//         artists: [{ name: "Queen" }],
//         name: "Another One Bites the Dust"
//     },
//     {
//         artists: [{ name: "Queen" }],
//         name: "Another One Bites the Dust - Remastered"
//     }
// ];

// const uniqueTracks = matcher.filterDuplicates(testTracks);
// console.log(uniqueTracks.length); // Should output 1
