import { SpotifyApi } from "@spotify/web-api-ts-sdk";
import type { User } from "@spotify/web-api-ts-sdk";
import { SpotifyAccess, SpotifyProfile } from "../../types/spotify";
import {
	setLocalStorage,
	getLocalStorage,
	hashValues,
	removeLocalStorage,
} from "../helpers";

const badResponseHandler = (response: any) => {
	if (response.error.status && response.error.message) {
		const errorCode = response.error.status;
		let responseaction;
		switch (errorCode) {
			case 401:
				responseaction = "Remove access_code";
				break;

			default:
				console.log("Sure, Not");
				break;
		}
		return responseaction;
	}
};

const checkUserProfile = async (token?: any) => {
	const spotUserProfileEndpoint = "https://api.spotify.com/v1/me";
	if (token) {
		return await fetch(spotUserProfileEndpoint, {
			method: "GET",
			headers: {
				Authorization: "Bearer " + token,
			},
		})
			.then(async (response) => {
				const checkUserProfileResponse: User = await response.json();
				if (response.ok) {
					const userProfileData: User = checkUserProfileResponse;
					return userProfileData;
				} else {
					// console.log(badResponseHandler(checkUserProfileResponse));
					// console.log(checkUserProfileResponse);
					return Promise.resolve(badResponseHandler(checkUserProfileResponse));
				}
			})
			.catch((error) => {
				// console.log(error);
				throw new Error(error);
			});
	} else {
		return "authentication needed";
	}
};

const setStorageData = (access?: SpotifyAccess, profile?: SpotifyProfile | string| undefined) => {
	console.log(`🥮grim__storagedata==> ==> ==>storagedata`);
	console.log({grim__storagedata: access, profile});
	if (access) {
		setLocalStorage("access_code", access.access_token);
		setLocalStorage("expir_access_code", access.expires_in);
	}
	if (profile && typeof profile !== "string") {
		setLocalStorage("profile_image", profile.images[0].url);
		setLocalStorage("profile_name", profile.display_name);
		setLocalStorage("profile_api_href", profile.href);
		setLocalStorage("profile_id", profile.id);
	}
};

/** === ☠ ☠ ☠ ===>

Handling Spotify Auth Logic
if it's landing -> 
	(1) check local storage
		--> if 
if it's a callback url with access token -> 
	(1) validate token
		--> if valid -->
			(A) 
	(2) rewrite exsiting local storage value; show user profile

<=== ☠ ☠ ☠ === **/
const checkSpotifyAuth = async () => {
	const checkLocalAccessCode = getLocalStorage("access_code");
	// const spotifyAuthCallback: SpotifyAccess = hashValues();

	if (checkLocalAccessCode) {
		const getUserProfile = await checkUserProfile(checkLocalAccessCode);
		// getUserProfile.then((response: any) => response);
		// if(getUserProfile)
		console.log(getUserProfile);
		if (getUserProfile === "Remove access_code") {
			return removeLocalStorage("access_code");
		} else {
			setStorageData(undefined, getUserProfile);
			return getUserProfile;
		}
		// getUserProfile
	}
};


// <=== 💸  Check if user is logged in  💸 ===>
const userIsLoggedIn = () => {
	const spotifyUserProfile = document.getElementById('spotify-user-profile');
	if (spotifyUserProfile) {
		return true;
	} else {
		return false;
	}
}

export { checkSpotifyAuth,userIsLoggedIn };
