import * as React from "react";

import DotaSpottoTheme from "./dotaspottotheme";

import { checkSpotifyAuth } from "../service/spotify/authentication";

// <=== 💸  MUI Stuff  💸 ===>
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Slide from "@mui/material/Slide";
import { Button } from "@mui/material";

const token = process.env.REACT_APP_SPOTY_CLIENT_ID;
const redirectUrl = process.env.REACT_APP_REDIRECT_URI;
console.log(`🎠grim__REDIRECT_URI==>==>==>${redirectUrl}`);
const spotAuthEndpoint = process.env.REACT_APP_AUTH_ENDPOINT;
const RESPONSE_TYPE = "token";
// <=== 💸  Theme Settings  💸 ===>
const chipHeight = 60;
const dotaRed = DotaSpottoTheme.primary;
const themeFont = DotaSpottoTheme.font;
const highlightFont = DotaSpottoTheme.font2;
const loginTheme = createTheme({
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					":hover": {
						backgroundColor: dotaRed,
					},
				},
				avatar: {
					height: chipHeight,
					width: chipHeight,
					marginLeft: 0,
				},
				label: {
					fontSize: "1.25em",
					marginLeft: 10,
					marginRight: 10,
					fontFamily: highlightFont,
				},
				clickableColorSecondary: {
					backgroundColor: "green",
				},
				filledPrimary: {
					backgroundColor: "#000",
					backgroundImage: "linear-gradient(75deg, ${dotaRed}, gold)",
					borderRadius: chipHeight,
					height: chipHeight,
				},
			},
		},
	},
});
interface LoggedIn {
	active: boolean;
	hero?: any;
	event?: string | null;
}

const loginAlertActive = () => {
	const loginAlertIcon = document.getElementById("login-reminder");
	if (loginAlertIcon) {
		return true;
	} else {
		return false;
	}
};

const SpotifyLogin: React.FC<{
	spotifyauthentication: any;
	reminder: any;
}> = ({ spotifyauthentication, reminder }) => {
	// <=== 💸  START States  💸 ===>
	const [authorization, setSpotifyAuthorization] = React.useState<any | null>({
		status: "checking",
	});

	const [loginalert, setLoginAlert] = React.useState<LoggedIn>({
		active: false,
	});
	const spotifyLoginButton = document.getElementById("spotify-login-link");
	const loginHover = (e: any) => {
		const loginAlertCheck = loginAlertActive();
		
		if (
			e.target.id === "spotify-login-link" &&
			loginalert.event === "click" &&
			loginAlertCheck
		) {
			setLoginAlert({
				active: false,
				event: e.type,
			});
		}
	};

	if (reminder && spotifyLoginButton) {
		const loginAlertCheckClick = loginAlertActive();

		if (loginalert.active === false && !loginAlertCheckClick) {

			setLoginAlert({
				active: true,
				event: reminder.eventType,
			});
		}
	}

	// <=== 💸  END States  💸 ===>

	if (authorization.status === "checking") {
		const SpotifyAuthorization = checkSpotifyAuth();
		SpotifyAuthorization.then((response: any) => {
			// console.log(response);
			if (response) {
				setSpotifyAuthorization({
					status: response,
				});
				spotifyauthentication(response);
			} else {
				setSpotifyAuthorization({
					status: "failed",
				});
			}
		}).catch((error) => {
			console.log(error);
		});
	}
	return (
		<Container style={{ marginBottom: 30}}>
			<ThemeProvider theme={loginTheme}>
				{(!authorization.status ||
					authorization.status === "Remove access_code" ||
					authorization.status === "authentication needed" ||
					authorization.status === "failed") && (
					<Box
						sx={{
							display: "flex",
							alignItems: "flex-start",
						}}
					>
						<Button
							id="spotify-login-link"
							variant="contained"
							href={`${spotAuthEndpoint}?client_id=${token}&redirect_uri=${redirectUrl}&scope=playlist-modify-public&response_type=${RESPONSE_TYPE}`}
							onMouseEnter={loginHover}
						>
							Login to Spotify
						</Button>
						<Slide
							direction="left"
							in={loginalert.active}
							mountOnEnter
							unmountOnExit
							style={{
								display: "inline-flex",
							}}
						>
							<Box>
								<img
									src="/mirana_ohlook.png"
									height={40}
									width={40}
									id="login-reminder"
								/>
							</Box>
						</Slide>
					</Box>
				)}
				{authorization.status.type === "user" && (
					<>
						<h1>
							<strong>Welcome, {authorization.status.display_name}</strong>
						</h1>
						<Box id="spotify-user-profile">
							<Stack direction="row" spacing={1}>
								<Chip
									avatar={
										<Avatar
											sizes="medium"
											alt={`${authorization.status.display_name} Portrait`}
											src={authorization.status.images[0].url}
										/>
									}
									component="a"
									target="_blank"
									color="primary"
									// sx={{ height: 70 }}
									size="medium"
									href={authorization.status.external_urls.spotify}
									label={authorization.status.display_name}
									clickable
								/>
							</Stack>
						</Box>
					</>
				)}
			</ThemeProvider>
		</Container>
	);
};

export default SpotifyLogin;
