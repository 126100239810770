import * as React from "react";

import Heroes from "./dotaselector";
import SpotifyTing from "./playlist";
import SpotifyLogin from "./spotifylogin";
import { Divider, Box, Modal, Button, Fade } from "@mui/material";
import { getSpotifyDetail } from "../service/helpers";
import { Container } from "@mui/material";

const HeroSelect: React.FC<{}> = () => {
	const [spotifyauth, setSpotifyAuth] = React.useState<any | null>({
		status: "checking",
		type: null,
	});

	const spotifyauthentication = (index: any) => {
		if (index.type === "user") {
			const accountToken = getSpotifyDetail.getAuthToken();
			setSpotifyAuth({
				status: accountToken,
				type: "token",
			});
		}
	};

	const [thehero, setTheHero] = React.useState<any | null>({
		status: "loading",
	});

	const sendDataToGrandParent = (index: any) => {
		setTheHero({
			status: "loaded",
			payload: index,
		});
	};
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const style = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		color: "#000",
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 4,
	};
	console.log(`🥮grim__thehero==> ==> ==>thehero`);
	console.log({grim__thehero: thehero});
	return (
		<>
			{/* <SpotifyLogin getData={thehero} /> */}
			<SpotifyLogin
				spotifyauthentication={spotifyauthentication}
				reminder={thehero.payload}
			/>
			<Heroes sendDataToGrandParent={sendDataToGrandParent} />
			{spotifyauth.type === "token" && thehero.status === "loaded" && (
				<SpotifyTing thehero={thehero} spotifyauth={spotifyauth} />
			)}
			<Divider></Divider>
			<Container>
				<Button
					onClick={handleOpen}
					variant="contained"
					size="large"
				>Credits</Button>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={open}
					onClose={handleClose}
					closeAfterTransition
				// BackdropComponent={Backdrop}
				// BackdropProps={{
				// 	timeout: 500,
				// }}
				>
					<Fade in={open}>
						<Box sx={style}>
							<>That's what's up</>
						</Box>
					</Fade>
				</Modal>
			</Container>
		</>
	);
};
export default HeroSelect;
